import $ from "jquery";
// window.$ = window.jQuery = $;
import "slick-carousel";
import offside from "offside-js";

$(document).ready(function () {
  window.offsideRef = offside("#mainMenu", {
    debug: false, // Boolean: If true, print errors in console
    slidingSide: "right",
    slidingElementsSelector: "#content-wrapper",
    buttonsSelector: "#toggleMobileMenu, .another-button",
  });
});

$(document).ready(function () {
  $("#functional-description-slider").slick({
    infinite: false,
    prevArrow: $("#functional-description-slider-prev"),
    nextArrow: $("#functional-description-slider-next"),
  });

  $("#feature-description-slider").slick({
    infinite: false,
    slidesToShow: 3,
    prevArrow: $("#feature-description-slider-prev"),
    nextArrow: $("#feature-description-slider-next"),
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  $("#wbe-process-requirement-slider").slick({
    infinite: false,
    slidesToShow: 1,
    nextArrow: $("#wbe-process-requirement-slider-next"),
    prevArrow: $("#wbe-process-requirement-slider-prev")
  });

  var fform = $(".freeform form")[0];
  if (fform) {
    fform.addEventListener("freeform-ready", function (event) {
      var freeform = event.target.freeform;
      console.log(freeform);
      freeform.addOnSuccessfulAjaxSubmit((event, fform, response) => {
        $(".freeform .form").hide();
        $(".freeform .success").show();
      });
    });
  }

  $(".btn.btn-primary").addClass("cta");
});
// $(document).ready(function(){
//   console.log('13')
//   $('.slider').slick({
//     infinite: true,
//     slidesToShow: 1,
//     prevArrow: '<button><svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><ellipse cx="11.5" cy="13.5" rx="10.5" ry="8.5" transform="matrix(.95238 0 0 1.17647 1.048 -3.882)"/><path d="M13.5 7l-5 5 5 5V7z" fill="#fff"/></svg></button>',
//     nextArrow: '<button><svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><ellipse cx="11.5" cy="13.5" rx="10.5" ry="8.5" transform="matrix(.95238 0 0 1.17647 1.048 -3.882)"/><path d="M10.5 17l5-5-5-5v10z" fill="#fff"/></svg></button>'
//   });
// });
